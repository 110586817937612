<template>
  <b-navbar toggleable="lg" type="dark" variant="dark" class="custom-navbar">
    <b-navbar-brand variant="light" href="/">Blinkwater</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" class="custom-toggler">
      <span class="custom-toggler-icon"></span>
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" class="navLists" is-nav ref="navCollapse">
      <b-navbar-nav class="ml-auto">
        <ul>
          <li><router-link to="/" class="NavLink" @click="collapseMenu">Home</router-link></li>
          <li><router-link to="/meetTheCharacters" class="NavLink" @click="collapseMenu">Meet the Characters</router-link></li>
          <li><router-link to="/creativeTeam" class="NavLink" @click="collapseMenu">Creative Team</router-link></li>
          <li><router-link to="/music" class="NavLink" @click="collapseMenu">Music</router-link></li>
          <li style="color: rgb(105,105,105); cursor: default;">Making of</li>
          <li><router-link to="/getInvolved" class="NavLink" @click="collapseMenu">Get Involved</router-link></li>
        </ul>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavBar',
  methods: {
    collapseMenu() {
      const collapse = this.$refs.navCollapse;
      if (collapse && collapse.hide) {
        collapse.hide(); 
      }
    },
  },
};
</script>

<style scoped>
.custom-navbar {
  background-color: rgba(18, 52, 86, 0.7);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.custom-toggler {
  border: none;
  padding: 0;
  margin-top: -10px;
}
.navbar-toggler:focus {
  box-shadow: none;
}

.custom-toggler .custom-toggler-icon {
  display: flex;
  width: 30px;
  height: 2px;
  background-color: whitesmoke;
  position: relative;
  transition: background-color 0.3s ease;
}

.custom-toggler .custom-toggler-icon::before,
.custom-toggler .custom-toggler-icon::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background-color: whitesmoke;
  transition: all 0.3s ease;
}

.custom-toggler .custom-toggler-icon::before {
  top: -8px;
}

.custom-toggler .custom-toggler-icon::after {
  bottom: -8px;
}

.navLists {
  flex-wrap: wrap;
  justify-content: end !important;
  color: red;
}

ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}

li {
  margin: 0.5em 1em;
}

.NavLink {
  color: white;
  text-decoration: none;
  margin-right: 1em;
  justify-content: end;
}

.NavLink:hover {
  text-decoration: underline;
}

.b-collapse:not(.show) {
  display: none;
  justify-content: end;
}

.b-collapse.show {
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  ul {
    flex-direction: column;
    justify-content: end;
  }
}
</style>
