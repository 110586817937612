@ -1,403 +1,414 @@
<template>
  <div v-if="!content && !title && pictureSource">
    <div
      ref="imageCard"
      class="row backdrop imageOnly"
      :style="isMobile ? '' : imgOnlyStylesContainer"
    >
      <img loading="lazy"
        :src="pictureSource"
        alt="Image"
        :style="isMobile ? '' : imgOnlyStyles"
      />
    </div>
  </div>

  <div v-if="content || title">
    <div
      class="row backdrop"
      v-if="!invertPanels"
      :class="[isMeetTheCharacters ? 'close' : '', customClass, !content && title ? 'headingOnly' : '' ]"
    >
      <div
        class="col-md-6 textCard"
        :class="{ blurred: outOfFocus }"
        ref="textCard"
      >
        <h1>{{ title }}</h1>
        <p v-if="content">{{ content }}</p>
      </div>
      <div v-if="pictureSource" class="col-md-6 col-lg-6 pictureCard">
        <img loading="lazy"
          class="rightImg"
          :src="pictureSource"
          alt="Image"
          :style="isCreativeCast ? zImgStyles : rImgStyles"
        />
      </div>
    </div>

    <div
      class="row backdrop"
      v-else
      :class="[
        isMeetTheCharacters ? 'close' : '',
        customClass ? customClass : '',
      ]"
    >
      <div v-if="pictureSource" class="col-md-6 col-lg-6 pictureCard">
        <img loading="lazy"
          class="leftImg"
          :src="pictureSource"
          alt="Image"
          :style="isCreativeCast ? nImgStyles : lImgStyles"
        />
      </div>
      <div
        class="col-md-6 textCard"
        :class="{ blurred: outOfFocus }"
        ref="textCard"
      >
        <h1>{{ title }}</h1>
        <p v-if="content">{{ content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationPanel",
  props: {
    title: String,
    content: String,
    pictureSource: String,
    invertPanels: Boolean,
    isMeetTheCharacters: Boolean,
    customClass: String,
    outOfFocus: Boolean,
    isCreativeCast: Boolean,
  },
  data() {
    const initial = {
      width: `${window.innerWidth * 0.7}px`,
      height: `${window.innerHeight * 0.7}px`,
      top: `${window.innerHeight * 0.07}px`,
      left: `${-window.innerWidth * 0.07}px`,
    };
    const imageOnly = !this.content && !this.title && this.pictureSource;
    
    if (this.isCreativeCast) {
      return {
        zImgStyles: {
          width: `${window.innerWidth * 0.007}px`,
          height: `${window.innerHeight * 0.0004}px`,
          top: `${-window.innerHeight * 0.07}px`,
          left: `${-window.innerWidth * 0.007}px`,
        },
        nImgStyles: {
          width: initial.width,
          height: initial.height,
          top: initial.top,
          left: initial.left,
        },
        imageOnly: imageOnly,
      };
    } else if (imageOnly) {
      return {
        imgOnlyStyles: {
          ...initial,
          position: "relative",
        },
        imgOnlyStylesContainer: {
          width: initial.width,
          height: initial.height,
        },
        isMobile: window.innerWidth <= 768,
        imageOnly: imageOnly,
      };
    } else {
      return {
        rImgStyles: {
          ...initial,
        },
        lImgStyles: {
          ...initial,
        },
        imageOnly: imageOnly,
      };
    }
  },
  mounted() {
  this.resizeObserver = new ResizeObserver(() => {
    this.updateImageStyles();
  });

  if (this.imageOnly && this.$refs.imageCard) {
    this.resizeObserver.observe(this.$refs.imageCard);
  } else if (this.$refs.textCard) {
    this.resizeObserver.observe(this.$refs.textCard);
  }


  const debouncedUpdate = this.debounce(this.updateImageStyles, 100);
  window.addEventListener("resize", debouncedUpdate);


  this.updateImageStyles();
  this.forceInitialResize();
},
methods: {
  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  },
  updateImageStyles() {
    const widthRatio = 0.34;
    const topRatio = 0.001;
    const leftRatio = 0.06;
    const windowWidthCalc = window.innerWidth * widthRatio;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const topDiff = -window.innerHeight * topRatio;
    const leftDiff = -window.innerWidth * leftRatio;

    const textCardHeight =
      this.$refs.textCard?.offsetHeight * 1.5 || window.innerHeight * 0.8;

    this.isMobile = window.innerWidth <= 768;

    if (this.isCreativeCast) {
        this.zImgStyles = {
          width: `${windowWidthCalc}px`,
          height: `${textCardHeight * 1.5}px`,
          top: `${topDiff}px`,
          left: `${leftDiff}px`,
        };

        this.nImgStyles = {
          width: `${window.innerWidth * 0.3}px`,
          height: `${textCardHeight * 1.9}px`,
          top: `${topDiff}px`,
          left: `${-window.innerWidth * leftRatio}px`,
        };
      } else if (this.imageOnly) {
        if (this.isMobile) {
          this.imgOnlyStyles = {};
          this.imgOnlyStylesContainer = {};
        } else {
          this.imgOnlyStyles = {
            width: `${windowWidth * 0.5}px`,
            height: `${windowHeight * 0.8}px`,
            top: `${topDiff * 150}px`,
            left: `${leftDiff * 0.8}px`,
            position: "absolute",
          };
          this.imgOnlyStylesContainer = {
            width: `${windowWidth * 0.35}px`,
            height: `${windowHeight * 0.35}px`,
          };
        }
      } else {
        this.rImgStyles = {
          width: `${windowWidthCalc}px`,
          height: `${textCardHeight}px`,
          top: `${topDiff}px`,
          left: `${leftDiff}px`,
        };

        this.lImgStyles = {
          width: `${windowWidthCalc}px`,
          height: `${textCardHeight}px`,
          top: `${topDiff}px`,
          left: `${-window.innerWidth * leftRatio}px`,
        };
      }
    },
  forceInitialResize() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100); // Delay slightly to ensure DOM is fully rendered
  },
},
  beforeUnmount() {
    if (this.resizeObserver) {
    this.resizeObserver.disconnect();
  }
    window.removeEventListener("resize", this.debounce(this.updateImageStyles, 100));
  },
};
</script>

<style scoped>
.backdrop {
  width: 65%;
  transform: skewX(-10deg);
  justify-self: center;
  margin: 3% auto 5%;
  display: flex;
  position: relative;
  height: fit-content;
  background-color: transparent;
}

.close {
  margin: 0% auto;
}

.textCard h1,
.textCard p {
  color: whitesmoke;
  mix-blend-mode: lighten;
}

.textCard p {
  font-size: 1.3em;
}

.textCard {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  flex: 1;
  pointer-events: none;
  font-family: Lexend;
}

.pictureCard {
  width: 30%;
  background-color: transparent;
  max-height: 100%;
  overflow: hidden;
  position: relative;
}

.leftImg,
.rightImg {
  position: absolute;
  transform: skewX(10deg);
  object-fit: cover;
  transition: all 0.3s ease;
}
.headingOnly{
  width: 100%;
}
.imageOnly {
  transition: all 0.3s ease;
  overflow: hidden;
}

.imageOnly img {
  transform: skewX(10deg);
  max-width: none;
}

.zanderClass {
  min-height: 90% !important;
  margin: 3% 0 5% !important;
  object-fit: cover;
  transition: all 0.3s ease;
}

.nicaClass {
  min-height: 90% !important;
  margin: 3% 0 5% !important;
  object-fit: cover;
  transition: all 0.3s ease;
}

.getInvolved {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  flex: 1;
  pointer-events: none;
}

.blurred {
  filter: blur(2px);
}

.infoClass {
  min-height: 40vh;
  text-align: center;
}

@media (max-width: 768px) {
  .backdrop {
    transform: skewX(0deg);
    width: 80%;
    margin: 0 auto;
    width: fit-content;
  }

  .textCard,
  .pictureCard {
    width: 100%;
  }

  .backdrop {
    flex-direction: column;
    align-items: center;
  }

  .textCard {
    padding: 15px;
  }

  .textCard h1 {
    font-size: 1.5em;
  }

  .textCard p {
    font-size: 1em;
  }

  .leftImg,
  .rightImg {
    max-width: 438px;
    height: 250px !important;
    transform: skewX(0deg);
    top: -5%;
    left: 0;
    position: relative;
  }

  .zanderClass {
    min-height: 90% !important;
    margin: 3% 0 5% !important;
    display: flex;
    flex-direction: column-reverse;
    width: 90%;
  }
  .pictureCard {
    height: 230px;
  }
  .zanderClass img {

    left: 0 !important;
  }

  .imageOnly {
    max-width: 438px;
    height: 250px !important;
    transform: skewX(0deg);
  }

  .imageOnly img {
    max-width: 438px;
    height: 250px !important;
    transform: skewX(0deg);
    top: -5%;
    left: 0;
  }
  .nicaClass {
    margin: 3% 0 5% !important;
    display: flex;
    flex-direction: column;
    width: 90%;
  }
  .nicaClass img {

    left: 0 !important;
    position: relative;
  }
}
</style>
