<template>
  <div class="getInvolvedPage">
    <div class="heading">
      <InformationPanel title="Get Involved" :isMeetTheCharacters="false" />
    </div>

    <div class="formContainer">
      <Form @submit="submitForm">
        <label>Name:</label>
        <Field
          v-model="formData.name"
          as="input"
          type="text"
          rules="required"
          name="name"
          placeholder="Name"
        />
        <ErrorMessage name="name" class="errorMessage" />

        <label>Email:</label>
        <Field
          v-model="formData.email"
          as="input"
          type="email"
          name="email"
          placeholder="Your Email"
          rules="required|email"
        />
        <ErrorMessage name="email" class="errorMessage" />

        <label>Message:</label>
        <Field
          v-model="formData.message"
          as="textarea"
          name="message"
          rules="required|min:10|max:250"
          placeholder="Your Message"
          maxlength="250"
        />
        <div class="charCounter">
          {{ formData.message.length }} / 250 characters
        </div>
        <ErrorMessage name="message" class="errorMessage" />
        <div v-if="useRecaptchaV2 && !captchaVerified" class="captcha">
          <div
            class="g-recaptcha"
            :data-sitekey="recaptchaKey"
            data-callback="onCaptchaVerified"
            ref="recaptcha"
          ></div>
        </div>
        <button
          type="submit"
          :disabled="
            !captchaVerified ||
            sendingEmail ||
            formData.name.length == 0 ||
            formData.email.length == 0 ||
            formData.message.length == 0
          "
        >
          Send Message
        </button>
      </Form>

      <div class="contact">
        <div class="mediums">
          <h3>Contact us</h3>
          <div class="emails">
            <a href="mailto:blinkwaterthemusical">
              <font-awesome-icon :icon="['far', 'envelope']" />
              blinkwaterthemusical@email.com
            </a>
          </div>
          <div class="numbers">
            <span>
              <font-awesome-icon :icon="['fab', 'whatsapp']" />
              012 345 6789
            </span>
            <span>
              <font-awesome-icon :icon="['fab', 'whatsapp']" />
              012 345 6789
            </span>
          </div>
        </div>
        <div class="socials">
          <a href="https://facebook.com" target="_blank">
            <font-awesome-icon :icon="['fab', 'facebook']" />
          </a>
          <a href="https://twitter.com" target="_blank">
            <font-awesome-icon :icon="['fab', 'twitter']" />
          </a>
          <a href="https://instagram.com" target="_blank">
            <font-awesome-icon :icon="['fab', 'instagram']" />
          </a>
          <a href="https://ticktock.com" target="_blank">
            <font-awesome-icon :icon="['fab', 'tiktok']" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InformationPanel from "@/components/InformationPanel.vue";
import emailjs from "emailjs-com";
import { useReCaptcha } from "vue-recaptcha-v3";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { Form, Field, ErrorMessage } from "vee-validate";
import { required, email, min } from "@vee-validate/rules";
import { defineRule } from "vee-validate";

const notificationTypes = {
  info: "info",
  success: "success",
  error: "error",
};

const notificationOptions = {
  theme: "dark",
  pauseOnHover: false,
  position: "bottom-right",
};

export default {
  name: "CreativeTeam",
  components: {
    InformationPanel,
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      sendingEmail: false,
      captchaVerified: false,
      useRecaptchaV2: false,
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_V2_KEY,
      recaptchaToken: "",
    };
  },
  methods: {
    showToasterNotification(message, type, isPromise) {
      if (isPromise) {
        return toast.promise(
          isPromise,
          {
            pending: message,
            success: "Email sent",
            error: "Failed to send email, please try again later.",
          },
          { ...notificationOptions }
        );
      } else {
        toast(message, { type, ...notificationOptions });
      }
    },

    onCaptchaVerified(token) {
      this.recaptchaToken = token;
      this.captchaVerified = true;
      sessionStorage.setItem("recaptchaValidated", "true");
      this.showToasterNotification(
        "Captcha verified",
        notificationTypes.success
      );
    },

    onRecaptchaLoad() {
      if (typeof window.grecaptcha !== "undefined" && this.$refs.recaptcha) {
        window.grecaptcha.render(this.$refs.recaptcha, {
          sitekey: this.recaptchaKey,
          callback: this.onCaptchaVerified,
          "expired-callback": this.onCaptchaExpired,
        });

        this.$nextTick(() => {
          this.adjustFormHeight();
        });
      } else {
        console.error(
          "window.grecaptcha is not defined or recaptcha ref is missing."
        );
      }
    },

    onCaptchaExpired() {
      this.recaptchaToken = "";
      this.captchaVerified = false;
    },

    async submitForm() {
      if (sessionStorage.getItem("recaptchaValidated") === "true") {
        this.sendEmail();
      } else {
        if (!this.useRecaptchaV2) {
          try {
            const token = await this.executeRecaptcha();
            if (token) {
              this.sendEmail();
            } else {
              this.showToasterNotification(
                "Captcha verification failed, please try again.",
                notificationTypes.error
              );
            }
          } catch (error) {
            this.showToasterNotification(
              "Captcha verification failed.",
              notificationTypes.error
            );
          }
        } else {
          if (this.recaptchaToken) {
            sessionStorage.setItem("recaptchaValidated", "true");
            this.sendEmail();
          } else {
            this.showToasterNotification(
              "Please complete the CAPTCHA",
              notificationTypes.info
            );
          }
        }
      }
    },

    async sendEmail() {
      this.sendingEmail = true;
      const serviceID = process.env.VUE_APP_EMAILJS_SERVICE_ID;
      const templateID = process.env.VUE_APP_EMAILJS_TEMPLATE_ID;
      const userID = process.env.VUE_APP_EMAILJS_USER_ID;

      const templateParams = {
        name: this.formData.name,
        email: this.formData.email,
        message: this.formData.message,
        "g-recaptcha-response": this.recaptchaToken,
      };

      try {
        const emailPromise = new Promise((resolve, reject) => {
          emailjs
            .send(serviceID, templateID, templateParams, userID)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });

        this.showToasterNotification(
          "Sending Email",
          notificationTypes.info,
          emailPromise
        );
      } catch (error) {
        console.error("Failed to send email:", error);
      } finally {
        this.sendingEmail = false;
      }
    },

    initRecaptchaV2() {
      if (typeof window.grecaptcha !== "undefined" || this.captchaVerified) {
        return;
      }

      const recaptchaScript = document.createElement("script");
      recaptchaScript.src =
        "https://www.google.com/recaptcha/api.js?render=explicit";
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      recaptchaScript.onload = () => {
        console.log("reCAPTCHA script loaded.");
        this.onRecaptchaLoad();
      };
      document.head.appendChild(recaptchaScript);
    },

    adjustFormHeight() {
      const formContainer = this.$el.querySelector(".formContainer");
      if (formContainer) {
        formContainer.style.height = "auto";
      }
    },
  },

  mounted() {
    if (sessionStorage.getItem("recaptchaValidated") === "true") {
      this.captchaVerified = true;
    } else {
      const timeout = setTimeout(() => {
        this.useRecaptchaV2 = true;
        this.$nextTick(() => {
          this.initRecaptchaV2();
        });
      }, 5000);

      this.executeRecaptcha()
        .then((token) => {
          if (token) {
            clearTimeout(timeout);
            this.captchaVerified = true;
            sessionStorage.setItem("recaptchaValidated", "true");
          }
        })
        .catch(() => {
          this.useRecaptchaV2 = true;
          this.$nextTick(() => {
            this.initRecaptchaV2();
          });
        });
    }
  },

  setup() {
    const { executeRecaptcha } = useReCaptcha();
    defineRule("required", (value) => {
      if (!value || !value.trim()) {
        return "Name is required";
      }
      return true;
    });

    defineRule("email", (value) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        return "Please enter a valid email address.";
      }
      return true;
    });

    defineRule("min", (value, [minLength]) => {
      if (value.length < minLength) {
        return `Please enter at least ${minLength} characters.`;
      }
      return true;
    });

    defineRule("max", (value, [maxLength]) => {
      if (value && value.length > maxLength) {
        return `This field must be ${maxLength} characters or fewer.`;
      }
      return true;
    });

    return {
      executeRecaptcha,
    };
  },
};
</script>

<style scoped>
.getInvolvedPage {
  background-image: url("../assets/pictures/background.webp");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 10%;
  padding-top: 5%;
  position: relative;
}

.formContainer {
  background-color: rgba(0, 0, 0, 0.7);
  width: 70%;
  margin: auto;
  padding: 4% 5%;
  box-shadow: -15px 0 20px -5px rgb(0, 0, 0), 15px 0 20px -5px rgb(0, 0, 0);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: visible;
}

form {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

form > label {
  color: beige;
  margin-bottom: 5px;
}

form > input,
form > textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  padding: 5px;
  margin-bottom: 15px;
}

textarea {
  resize: vertical;
}

button {
  border: none;
  border-radius: 5px;
  background-color: rgba(168, 170, 164, 0.7);
  color: white;
  padding: 10px;
  margin-top: 5%;
  cursor: pointer;
  align-self: flex-start;
}
button:disabled {
  background-color: transparent;
  border: 1px wheat solid;
}

.contact {
  flex: 0 0 35%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mediums {
  color: beige;
  text-align: left;
}

.mediums h3 {
  margin-bottom: 4%;
}

.emails,
.numbers {
  color: beige;
  font-size: large;
  line-height: 1.5em;
}

.emails a,
.numbers span {
  display: block;
  padding-bottom: 5%;
  color: #9e9e9e;
  text-decoration: none;
}

.socials {
  text-align: center;
}

.socials a {
  font-size: 2em;
  margin: 10px;
  color: #9e9e9e;
}

.captcha {
  margin-top: 20px;
  display: flex;
  justify-content: end;
}

section p {
  color: bisque;
}

.errorMessage {
  color: rgb(198, 78, 78);
}

.charCounter {
  font-size: 0.6em;
  color: gray;
  margin-top: -10px;
  text-align: right;
}

@media screen and (max-width: 768px) {
  .getInvolvedPage {
    padding-bottom: 40%;
    padding-top: 10%;
  }

  .formContainer {
    width: 90%;
    flex-direction: column;
    padding: 5%;
  }

  form,
  .contact {
    width: 100%;
    margin-right: 0;
  }

  .contact {
    margin-top: 20px;
    text-align: center;
  }

  .mediums,
  .socials {
    text-align: center;
  }

  .socials a {
    font-size: 1.5em;
  }

  .captcha {
    justify-self: center;
  }
  button {
    align-self: center;
  }
  .heading {
    transform: skewX(-5deg);
    width: 90%;
    margin: 0 auto;
  }
}
</style>
