<template>
  <div class="homepage-container">
    <HomeBanner @loaded="isLoading = false"/>
    <LoadingOverlay :isLoading="isLoading" />
    <div class="blackPart">
      <div v-for="(data, index) in HomePageData" :key="index" class="homeContent">
      <Carousel :images="data.images" :isHome="true"/>
      <div class="textContainer">
        <div class="box">
          <p>{{ data.description }}</p>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeBanner from "@/components/static/HomeBanner.vue";
import HomePageData from "@/constants/HomePage";
import Carousel from "@/components/Carousel.vue";
import images from "@/constants/images";
import LoadingOverlay from "@/components/static/LoadingOverlay.vue";

export default {
  name: "HomePage",
  components: {
    Carousel,
    HomeBanner,
    LoadingOverlay
  },
  data() {
    return {
      HomePageData,
      images,
      isLoading: true,
    };
  },
};
</script>

<style scoped>
.box{
  border-bottom: 1px whitesmoke solid;
  width: 80%;
  margin: auto;
  font-family: Lexend;
  margin-bottom: 4%;
}

.blackPart {
  width: 90%;
  height: fit-content;
  background-color: rgb(34, 34, 34);
  padding: 5% 0;
  color: whitesmoke;
  font-family: Lexend;
}
.homepage-container {
  background-image: url("../assets/pictures/background.webp");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 10%;
}

.homeContent{
  width: 90%;
  display: inline-block;
}
.infoClass{
  min-height: max-content;
}
.textContainer {
  display: grid;
  grid-template-columns: 1fr;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: fit-content;
}
h1{
  grid-column: 1;
}

p{
  grid-column: 2;
  text-align: justify;
  width: 100%;
}
.temp {
  margin-top: 5%;
  position: relative;
  transform: skewX(-10deg);
  justify-self: center;
  margin: 3% unset 5%;
  display: flex;
  border: 1px white solid;
  width: 60%;
  line-height: 2em;
}

.leftPanel,
.rightPanel {
  position: relative;
}
.leftPanel {
  margin-left: 5%;
}
.rightPanel {
  margin-right: 5%;
}
@media screen and (max-width: 768px) {
  .homepage-container {
    padding-bottom: 50%;
    padding-top: 10%;
}
}
</style>
