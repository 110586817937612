<template>
  <carousel
    :items-to-show="1"
    :wrapAround="true"
    :transition="2000"
    class="mb-2 carouselClass"
  >
  
    <slide v-for="(image, index) in images" :key="index">
      <InformationPanel 
            :isMeetTheCharacters="false"
            :pictureSource="image"
        /> 
    </slide>
    <template #addons>
      <navigation class="carousel__next" />
      <pagination />
    </template>
  </carousel>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import InformationPanel from "./InformationPanel.vue";

export default {
  name: "CarouselCard",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    InformationPanel,
  },
  props: {
    images: Object,
    isHome: Boolean,
  },
  data(){
    return {
      
    }
  }
};
</script>

<style scoped>
.carouselClass{
  height: fit-content;
  width: 100%;
  position: relative;
}

.slideClass{
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden
}
.carousel_item {
  width: 40%;
  max-width: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 100%
}

.slide{
  max-height: 50%;
  overflow: hidden;
}

.carousel__slide {
  padding: 10px;
}

:deep(.carousel__prev),
:deep(.carousel__next) {
  background-color: transparent;
  border: none;
  font-size: 2em;
  color: #fff;

}

:deep(.carousel__prev) svg,
:deep(.carousel__next) svg {
  width: 1em;
  height: 1em;
  fill: currentColor; 
}


:deep(.carousel__pagination) {
  background-color: transparent;
}

:deep(.carousel__pagination-button::after) {
  background-color: whitesmoke;
}
:deep(.carousel__pagination-button--active::after){
  background-color: #caaf38;
}

@media screen and (max-width: 768px) {
    .creativeTeamPage {
    padding-bottom: 20%;
    grid-template-rows: 0.1fr 1fr 1fr;
  }
  .heading{
      transform: skewX(-5deg);
      width: 90%;
      margin: 0 auto;
    }
    
}
</style>
