<template>
    <InformationPanel />
</template>

<script>
import InformationPanel from '../components/InformationPanel.vue';

export default {
    name: 'CreativeTeam',
    components: {
        InformationPanel
    },
} 
</script>

<style>

</style>