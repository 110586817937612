<template>
  <div class="creativeTeamPage">
    <LoadingOverlay :isLoading="isLoading" />
    <div class="heading">
      <InformationPanel title="Creative Team" :isMeetTheCharacters="false" />
    </div>

    <div class="zander">
      <InformationPanel
        :title="zander.name"
        :content="zander.lore"
        :isMeetTheCharacters="false"
        :invertPanels="false"
        :pictureSource="zander.headShot"
        :customClass="'zanderClass'"
        :isCreativeCast="true"
      />
    </div>
    <div class="nica">
      <InformationPanel
        :title="nica.name"
        :content="nica.lore"
        :isMeetTheCharacters="false"
        :invertPanels="true"
        :pictureSource="nica.headShot"
        :customClass="'nicaClass'"
        :isCreativeCast="true"
      />
    </div>
  </div>
</template>

<script>
import InformationPanel from "../components/InformationPanel.vue";
import creativeTeamData from "@/constants/CreativeTeam";
import LoadingOverlay from "@/components/static/LoadingOverlay.vue";

export default {
  name: "CreativeTeam",
  components: {
    InformationPanel,
    LoadingOverlay,
  },
  data() {
    return {
      zander: {},
      nica: {},
      cards: Object.entries(creativeTeamData).map(([name, char], index) => ({
        ...char,
        outOfFocus: false,
        id: index,
      })),
      isLoading: true,
      imagesToLoad: 0,
      imagesLoaded: 0,
    };
  },
  mounted() {
  // Assign specific cards
  this.zander = this.cards[0];
  this.nica = this.cards[1];

  // Count images and start loading
  this.imagesToLoad = this.cards.filter((card) => card.headShot).length;
  this.waitForImages();
},
methods: {
  waitForImages() {
    this.cards.forEach((card) => {
      if (card.headShot) {
        const img = new Image();
        img.src = card.headShot;
        img.onload = this.imageLoaded;
        img.onerror = () => {
          console.error(`Failed to load image: ${card.headShot}`);
          this.imageLoaded();
        };
      }
    });
  },
  imageLoaded() {
    this.imagesLoaded++;
    if (this.imagesLoaded >= this.imagesToLoad) {
      this.isLoading = false;
    }
  },
},


};
</script>

<style scoped>
.creativeTeamPage {
  background-image: url("../assets/pictures/background.webp");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 10%;
  position: relative;
}

.zander {
  grid-row: 2;
}

.nica {
  grid-row: 3;
}

@media screen and (max-width: 768px) {
  .creativeTeamPage {
    padding-bottom: 50%;
    padding-top: 10%;
    grid-template-rows: 0.1fr 1fr 1fr;
  }
  .heading {
    transform: skewX(-5deg);
    width: 90%;
    margin: 5% auto 0;
    
  }
}
</style>
