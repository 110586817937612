<template>
  <NavBar />
  <div class="mainWindow" ref="mainWindow">
    <router-view />
  </div>
  <FooterSection :isVisible="isFooterVisible" />
 
</template>

<script>
import NavBar from './components/static/Navigation.vue';
import FooterSection from './components/static/Footer.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    FooterSection
  },
  data() {
    return {
      isFooterVisible: false,
      lastScrollPosition: 0,
    };
  },
  mounted() {
    this.$nextTick(() => {
      const scrollableElement = this.$refs.mainWindow; // Use the ref
      if (scrollableElement) {
        console.log('Adding scroll listener to mainWindow');
        scrollableElement.addEventListener('scroll', this.handleScroll);
      } else {
        console.error('Scrollable element not found');
      }
    });
  },
  beforeUnmount() {
    const scrollableElement = this.$refs.mainWindow; // Use the ref
    if (scrollableElement) {
      scrollableElement.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    handleScroll(event) {
      const scrollableElement = event.target;
      const scrollTop = scrollableElement.scrollTop;
      const windowHeight = scrollableElement.clientHeight;
      const documentHeight = scrollableElement.scrollHeight;

      const isAtBottom = scrollTop + windowHeight >= documentHeight - 50;

      this.test = `${scrollTop} ${windowHeight} ${documentHeight}`;
      if (isAtBottom) {
        this.isFooterVisible = true;
      } else {
        this.isFooterVisible = false;
      }

      this.lastScrollPosition = scrollTop;
    },
  },
};
</script>

<style>
#app {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
  max-width: 100%;
  height: 100dvh;
  position: relative;
  overflow: hidden;
}

.mainWindow {
  height: 100dvh; /* Updated to prevent resizing issues on mobile */
  overflow: auto;
  position: relative;
}
.grecaptcha-badge {
  opacity: 0;
}

@font-face {
  font-family: 'Lexend';
  src: url('./assets/fonts/Lexend-VariableFont_wght.ttf');
}
</style>
