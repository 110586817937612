const HomePageData = {
//   mainImage: require(`@/assets/pictures/main.webp`),
  // experimentalImages : {
  //     life: require('@/assets/pictures/main.webp'),
  //     scorch: require('@/assets/pictures/main.webp')
  // },

  abstract: {
    description:
      `A British boy, coming of age in the early Southern African highveld, tries to find his place amidst clashing cultures and condemned love as the Boer War erupts.
      His bond with an Afrikaans Boer girl, and their future, hangs perilously in the balance.`,
    images: [require(`@/assets/pictures/image1.webp`), require(`@/assets/pictures/image2.webp`),require(`@/assets/pictures/image3.webp`)],
  },
  synopsis: {
    description:
      `At a pivotal moment in Southern Africa's formation, British-born Francis Johnson and Boer farm girl Ewa Fourie forge a childhood friendship out of unconventional 
      circumstances that blossoms into love as they reach adulthood. Their dreams of unity between their families and nations are shattered by the outbreak of war. 
      Torn apart by conflict, manipulation, and the struggle between duty, righteousness, and love, they are forced to make heart-wrenching choices. Returning after the war, 
      Francis seeks redemption and hopes to find Ewa. What begins as a quest for self-redemption soon devolves into a struggle against what seems to be a lost cause. 
      Can he mend what he was forced to leave behind? Does Ewa wish to be found?`,
    images: [require(`@/assets/pictures/image38.webp`), require(`@/assets/pictures/image40.webp`),require(`@/assets/pictures/image39.webp`)],
  },
  topic1: {
    description:
      `BLINK WATER positions itself as an amalgamation of Romantic drama and tragedy. It endeavours to represent marginalised groups differently than they have been portrayed in classical literature, 
      reimagining the world of the 1900s as one where women and African people possess similar agency and autonomy as non-marginalised groups.`,
    images: [require(`@/assets/pictures/image12.webp`), require(`@/assets/pictures/image22.webp`),require(`@/assets/pictures/image45.webp`)],
  },
  topic2: {
    description:
      `While acknowledging and illustrating the oppression and mistreatment these groups faced, the narrative remains accessible and uplifting, focusing on resilience and strength. 
      The tone inspires the audience to reflect on the progress made to date and the ongoing struggle for equality, all while delivering an engaging and thought-provoking experience.`,
    images: [require(`@/assets/pictures/image13.webp`), require(`@/assets/pictures/image23.webp`),require(`@/assets/pictures/image13.webp`)],
  },
  topic3: {
    description:
      `Similar to the narrative style, various musical devices from modern theatrical, pop, and classical music are employed in a modernised, 
      accessible form that still pays homage to the great composers and musicians of our time.`,
    images: [require(`@/assets/pictures/image18.webp`), require(`@/assets/pictures/image28.webp`),require(`@/assets/pictures/image38.webp`)],
  },

  // textData:{
  //     abstract: `A British boy, coming of age in the early Southern African highveld, tries to find his place amidst clashing cultures and condemned love as the Boer War erupts.His bond with an Afrikaans Boer girl, and their future, hangs perilously in the balance.`,
  //     synopsis: `At a pivotal moment in Southern Africa's formation, British-born Francis Johnson and Boer farm girl Ewa Fourie forge a childhood friendship out of unconventional circumstances that blossoms into love as they reach adulthood. Their dreams of unity between their families and nations are shattered by the outbreak of war. Torn apart by conflict, manipulation, and the struggle between duty, righteousness, and love, they are forced to make heart-wrenching choices. Returning after the war, Francis seeks redemption and hopes to find Ewa. What begins as a quest for self-redemption soon devolves into a struggle against what seems to be a lost cause. Can he mend what he was forced to leave behind? Does Ewa wish to be found?`,
  //         topic1: `BLINK WATER positions itself as an amalgamation of Romantic drama and tragedy. It endeavours to represent marginalised groups differently than they have been portrayed in classical literature, reimagining the world of the 1900s as one where women and African people possess similar agency and autonomy as non-marginalised groups.`,
  //         topic2:  `While acknowledging and illustrating the oppression and mistreatment these groups faced, the narrative remains accessible and uplifting, focusing on resilience and strength. The tone inspires the audience to reflect on the progress made to date and the ongoing struggle for equality, all while delivering an engaging and thought-provoking experience.`,
  //         topic3: `Similar to the narrative style, various musical devices from modern theatrical, pop, and classical music are employed in a modernised, accessible form that still pays homage to the great composers and musicians of our time.`,
  // }
};

export default HomePageData;
