<template>
  <footer :class="{ visible: isVisible }" class="slide-in-footer">
    <div class="footer-content">
      <ul class="footer-links">
        <li><router-link to="/" class="NavLink">Home</router-link></li>
        <li>
          <router-link to="/meetTheCharacters" class="NavLink">Meet the Characters</router-link>
        </li>
        <li>
          <router-link to="/creativeTeam" class="NavLink">Creative Team</router-link>
        </li>
        <li><router-link to="/music" class="NavLink">Music</router-link></li>
        <li>
          <router-link to="/getInvolved" class="NavLink">Get Involved</router-link>
        </li>
      </ul>
    </div>
    <div class="socials">
      <a href="https://facebook.com" target="_blank">
        <font-awesome-icon :icon="['fab', 'facebook']" />
      </a>
      <a href="https://twitter.com" target="_blank">
        <font-awesome-icon :icon="['fab', 'twitter']" />
      </a>
      <a href="https://instagram.com" target="_blank">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
      <a href="https://ticktock.com" target="_blank">
        <font-awesome-icon :icon="['fab', 'tiktok']" />
      </a>
    </div>
    <p>&copy; 2024 Blinkwater Musical. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  props: {
    isVisible: Boolean, // The visibility of the footer is passed from App.vue
  },
};
</script>

<style scoped>
.slide-in-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgb(33, 37, 41);
  color: white;
  text-align: center;
  padding: 20px;
  transition: transform 0.5s ease-in-out;
  transform: translateY(100%);
}

.slide-in-footer.visible {
  transform: translateY(0);
}

.footer-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.footer-links li {
  margin: 0 15px;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.socials {}

.socials a {
  font-size: 2em;
  margin: 10px;
  color: #9e9e9e;
  display: inline;
}

p {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .footer-links {
    display: none;
  }

  .footer-content p {
    font-size: 0.8em;
  }

  .socials a {
    font-size: 1.5em;
    margin: 10px;
    color: #9e9e9e;
    display: inline;
  }
}
</style>
