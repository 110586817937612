<template>
  <div class="page">
    <LoadingOverlay :isLoading="isLoading" />
    <div>
      <InformationPanel
        title="MEET THE CHARACTERS"
        :isMeetTheCharacters="false"
      />
    </div>
    <div
      v-for="(card, index) in cards"
      :key="card.id"
      :ref="
        (el) => {
          this.cardElements[index] = el;
        }
      "
      class="card-container"
    >
      <component
        :is="componentToRender"
        :content="card.description"
        :title="card.name"
        :pictureSource="card.image"
        :invertPanels="index % 2 == 0"
        :isMeetTheCharacters="true"
        :outOfFocus="card.outOfFocus"
      />
    </div>
  </div>
</template>

<script>
import InformationPanel from "../components/InformationPanel.vue";
import MeetTheCharactersData from "../constants/MeetTheCharacters.js";
import CharacterCard from "../components/FlipCard.vue";
import LoadingOverlay from "@/components/static/LoadingOverlay.vue";

export default {
  name: "MeetTheCharacters",
  components: {
    InformationPanel,
    CharacterCard,
    LoadingOverlay,
  },
  data() {
    return {
      isMobile: window.innerWidth <= 768,
      MeetTheCharactersData,
      observer: null,
      cardElements: [],
      cards: Object.entries(MeetTheCharactersData).map(
        ([name, char], index) => ({
          ...char,
          name,
          outOfFocus: false,
          id: index,
        })
      ),
      isLoading: true,
      imagesToLoad: 0,
      imagesLoaded: 0,
    };
  },

  computed: {
    componentToRender() {
      return this.isMobile ? "CharacterCard" : "InformationPanel";
    },
  },
  mounted() {
    this.imagesToLoad = this.cards.filter((card) => card.image).length;
    this.waitForImages();
    window.addEventListener("resize", this.onResize);
    this.$nextTick(() => {
      this.createObserver();
    });
  },
  methods: {
    waitForImages() {
      this.cards.forEach((card) => {
        const img = new Image();
        img.src = card.image;
        img.onload = this.imageLoaded;
        img.onerror = this.imageLoaded;
      });
    },
    imageLoaded() {
      this.imagesLoaded++;
      if (this.imagesLoaded >= this.imagesToLoad) {
        this.isLoading = false;
      }
    },
    createObserver() {
      const options = this.isMobile ? { threshold: 0.4 } : { threshold: 0.9 };
      this.observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );
      this.$nextTick(() => {
        this.cardElements.forEach((cardEl) => {
          if (cardEl) {
            this.observer.observe(cardEl);
          }
        });
      });
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        const cardEl = entry.target;
        const index = this.cardElements.indexOf(cardEl);

        if (index !== -1) {
          if (this.isMobile) {
            if (entry.intersectionRatio >= 0.4) {
              this.cards[index].outOfFocus = false;
              cardEl.classList.remove("grayscale");
              cardEl.classList.add("in-focus");
            } else {
              this.cards[index].outOfFocus = true;
              cardEl.classList.add("grayscale");
              cardEl.classList.remove("in-focus");
            }
          } else {
            if (entry.intersectionRatio >= 0.9) {
              this.cards[index].outOfFocus = false;
              cardEl.classList.remove("grayscale");
              cardEl.classList.add("in-focus");
            } else {
              this.cards[index].outOfFocus = true;
              cardEl.classList.add("grayscale");
              cardEl.classList.remove("in-focus");
            }
          }
        }
      });
    },
    onResize() {
      const prevIsMobile = this.isMobile;
      this.isMobile = window.innerWidth <= 768;

      if (this.isMobile !== prevIsMobile) {
        if (this.observer) {
          this.observer.disconnect();
        }
        this.cardElements = [];
        this.$nextTick(() => {
          this.createObserver();
        });
      }
    },
  },

  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    isMobile(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.observer) {
          this.observer.disconnect();
        }

        this.cardElements = [];

        this.$nextTick(() => {
          this.createObserver();
        });
      }
    },
  },
};
</script>

<style scoped>
.page {
  background-image: url("../assets/pictures/Background2.webp");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 15%;
}
.card-container {
  transition: filter 1s ease;
}

.card-container.grayscale {
  filter: grayscale(100%);
}

.card-container.in-focus {
  filter: grayscale(0%);
}
@media (max-width: 768px) {
  .page {
    padding-bottom: 50%;
    padding-top: 10%;
  }
}
</style>
