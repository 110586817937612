const meetTheCharacters = {
    Francis: {
        name: `FRANCIS JOHNSON`,
        description: `Francis Johnson, a young Englishman raised in Southern Africa, grapples with the weight of high expectations throughout his life. 
                    With a pure heart and steadfast values, he strives for approval and love, moreso later in life when he develops romantic feelings for a Boer woman.
                    As he ages, he adopts the traits of the Boer community, but his loyalty is tested by circumstances that challenge his beliefs. In the end, 
                    his determination to do what he deems right leads to tragedy.`,
        image: require("@/assets/pictures/image25.webp"),
    },
    Ewa: {
        name: `EWA FOURIE`,
        description: `Ewa Fourie, a strong-willed Boer girl from the titular Blinkwater farm, is known for her wit, cunning, and deep loyalty. 
                    She becomes a pillar of strength for her family through the hardships of the Anglo-Boer War. 
                    However, the horrors she endures eventually shatter her resilience, and when confronted by a figure from her past, 
                    her world is turned upside-down, even though it may be her one chance for survival.`,
        image: require("@/assets/pictures/image39.webp"),
    },
    Johan: {
        name: `JOHAN FOURIE`,
        description: `Johan Fourie, the proud owner of Blink Water Farm, embodies the traditional Boer man of the 1900s.
                    Though soft-spoken and wise, his deep loyalty to his farm, family, and country defines him. Guided by his wife, 
                    Anna, he begins to experience a challenge to his beliefs when a foreign family joins his own.
                    As circumstances spiral beyond his control, Johan faces conflict and backlash from his community, causing his faith to falter. 
                    He becomes a shadow of his former self, yet his pride remains, driving him to fiercely defend his farm till the bitter end.`,
        image: require("@/assets/pictures/image52.webp"),
    },
    Anna: {
        name: `ANNA FOURIE`,
        description: `Anna Fourie, the matriarch of Blinkwater Farm, embodies modesty, warmth, and quiet strength. A modern depiction of the Boer women who held their communities together. 
                    A wise and humble mother to Ewa and Albert, her deep faith and love for her family guide her actions. 
                    Though reserved, Anna’s presence commands respect, and her resilience supports not only her family but also the Johnsons through difficult times.
                    Anna never loses faith, even when faced with death and loss, always placing her loved ones before herself.`,
        image: require("@/assets/pictures/image64.webp"),
    },
    Albert: {
        name: `ALBERT FOURIE`,
        description: `A free-spirited Boer son from Blinkwater Farm, Albert approaches life with a carefree, sarcastic sense of humor and a love for pranks, unbound by tradition.
                    His bond with his sister, Ewa, is protective yet teasing—he takes pride in being the only one allowed to mess with her.
                    After initially clashing with Francis, the two form a close friendship. As Albert reaches adulthood, 
                    he is forced to reconcile his love for his family and friends within the harsh realities of the world and the Anglo-Boer War.`,
        image: require("@/assets/pictures/image36.webp"),
    },
    Richard: {
        name: `RICHARD JOHNSON`,
        description: `Richard Johnson, the English father figure, is open-minded and deeply rooted in his Christian faith. 
                        Passionate about sharing his faith through travel, he challenges those around him to see the world from new perspectives, often swayed and steered by his wife, Elizabeth.
                        When faced with difficult choices, Richard’s determination to hold things together is unwavering. 
                        Ultimately, he makes a heart-wrenching decision that profoundly affects those he loves most.`,
        image: require("@/assets/pictures/image19.webp"),
    },
    Elizabeth: {
        name: `ELIZABETH JOHNSON`,
        description: `Elizabeth Johnson, the spirited and independent English mother to Francis, is driven by her deep Christian faith, her belief in the good of all people, 
                        and her desire to make a difference.
                        Fearless and excited by the unknown, she eagerly embraces the challenges of starting a new life in Southern Africa.
                        Though resilient in the face of adversity, her greatest vulnerability is her son. As she witnesses his struggles, her unwavering faith begins to falter, 
                        forcing her to make an impossible decision about the future.`,
        image: require("@/assets/pictures/image26.webp"),
    },
    Maria: {
        name: `MARIA`,
        description: `Maria, a middle-aged woman of colour, is the quiet backbone of the Fourie household on Blinkwater Farm. She knows more about what’s happening than most realise.
                    A second mother and confidante to the children, Maria fosters connections and helps bridge the gap between Francis and the families.
                    With a deep, untold history marked by scandal and love, she holds a quiet wisdom that shapes the lives of the families and our story, 
                    embodying the unseen voices of coloured people lost during the war.`,
        image: require("@/assets/pictures/image48.webp"),
    },
    Langley: {
        name: `LIEUTENANT COLONEL LANGLEY`,
        description: `Lieutenant Colonel Langley, a British officer during the Anglo-Boer War, embodies the darker side of unprincipled authority within the British forces of the time.
                        Corrupt and short-tempered, he exploits his power for personal gain, preying on those more vulnerable, especially women, with little regard for the consequences of his actions on others, whether friend or foe.
                        Driven by an obsessive need for control, Langley never questions the morality of his actions. When confronted by a worthy adversary, his inability to handle opposition leads to his rapid unravelling, dragging down those around him.`,
        image: require("@/assets/pictures/image47.webp"),
    },
};

export default meetTheCharacters;
