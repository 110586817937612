<template>
  <div class="card-container" :class="{ expanded: isExpanded }" @click="toggleCard">
    <div class="card-image">
      <div class="name">
      <h1>{{ title }}</h1>
    </div>
      <img loading="lazy" :src="pictureSource" alt="Card Image" />
    </div>
    <div
      class="card-content"
       :class="{ blurred: outOfFocus }"
      :style="{ maxHeight: isExpanded ? `${contentHeight}px` : '0px' }"
      ref="cardContent"
    >
      <p>{{ content }}</p>
    </div>
  </div>
</template>


<script>
export default {
  name: "FlipCard",
  props: {
    title: String,
    content: String,
    pictureSource: String,
    outOfFocus: Boolean
  },
  data() {
    return {
      isExpanded: false,
      contentHeight: 0,
    };
  },
  methods: {
    toggleCard() {
      const cardContent = this.$refs.cardContent;

      if (!this.isExpanded) {
        this.contentHeight = cardContent.scrollHeight + 10;
      } else {
        this.contentHeight = 0;
      }

      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped>
.card-container {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s ease;
  background-color: rgba(0, 0, 0, 0.7);
  margin: 5%
}

.card-image {
  position: relative;
  overflow: hidden;
}
.blurred {
  filter: blur(2px);
  transition: filter 2s ease;
}
.name {
  position: absolute; 
  top: 0;
  left: 0;
  width: 100%;
  text-align: center; 
  background-color: rgba(0, 0, 0, 0.6); 
  color: white;
  padding: 10px 0; 
  overflow: hidden; 
  z-index: 1;
  font-family: Lexend;
}
h1{
  margin: 0;
}
.card-image img {
  width: 100%;
  height: auto;
  display: block;
  z-index: 0;
}

.card-container.expanded {
  transform: scale(1.02); 
}

.card-content {
  max-height: 0; 
  overflow: hidden;
  transition: max-height 0.4s ease, padding 0.4s ease; 
  padding: 0 20px; 
  color: whitesmoke;
  font-family: Lexend;
  text-align: justify;
}

.card-container.expanded .card-content {
  padding: 20px; 
}

</style>
