import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import { BNavbar, BNavbarBrand, BNavbarToggle, BNavItem, BNavbarNav, BCollapse } from 'bootstrap-vue-next';
import router from './router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faTwitter, faTiktok, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faInbox, faPhone } from '@fortawesome/free-solid-svg-icons';
import Vue3Toastify from 'vue3-toastify';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import './tailwind.css';

const app = createApp(App);
app.use(router);
app.use(Vue3Toastify, {autoClose: 3000,})
app.use(VueReCaptcha, {siteKey: process.env.VUE_APP_RECAPTCHA_KEY });

library.add(
    faFacebook,
    faTwitter,
    faInstagram,
    faTiktok,
    faInbox,
    faEnvelope,
    faPhone,
    faWhatsapp
);

// Bootstrap components
app.component('b-navbar', BNavbar);
app.component('b-navbar-nav', BNavbarNav);
app.component('b-navbar-brand', BNavbarBrand);
app.component('b-navbar-toggle', BNavbarToggle);
app.component('b-nav-item', BNavItem);
app.component('b-collapse', BCollapse);
app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
