import { createRouter, createWebHistory } from "vue-router";

import HomePage from "@/views/HomePage.vue";
import MeetTheCharacters from "@/views/MeetTheCharacters.vue";
import CreativeTeam from "@/views/CreativeTeam.vue";
import Music from "@/views/Music.vue";
import MakingOf from "@/views/MakingOf.vue";
import GetInvolved from "@/views/GetInvolved.vue";
import PageNotFound from "@/views/PageNotFound.vue";

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/meetTheCharacters',
        name: 'MeetTheCharacters',
        component: MeetTheCharacters,
    },
    {
        path: '/creativeTeam',
        name: 'CreativeTeam',
        component: CreativeTeam,
    },
    {
        path: '/music',
        name: 'Music',
        component: Music,
    },
    {
        path: '/makingOf',
        name: 'MakingOf',
        component: MakingOf,
    },
    {
        path: '/getInvolved',
        name: 'GetInvolved',
        component: GetInvolved,
    },
    {
        path: '/:catchAll(.*)*',
        name: 'NotFound',
        component: PageNotFound
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

 export default router;