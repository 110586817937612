<template>
  <div class="PageNotFound">
    <div class="backdrop">
      <div class="content">
        <h1>Page not Found</h1>
        <img loading="lazy" src="../assets/pictures/rests.webp" alt="">
        <p></p>
        <p>You seem to be lost. </p>
        <p>Here is a button for you to take you back</p>
        <router-link to="/" class="toHome">Home</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  components: {},
};
</script>

<style scoped>
.PageNotFound {
  background-image: url("../assets/pictures/background.webp");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 10%;
  padding-top: 5%;
  position: relative;
}

.content {
    padding: 5%;
    transform: skewX(10deg);
    width: 100%;
    height: 60vh;
    font-family: Lexend;
}

h1 {
  align-self: center;
}
.backdrop {
  width: 65%;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.7);
  transform: skewX(-10deg);
  justify-self: center;
  margin: 3% auto 5%;
  display: flex;
  position: relative;
  height: fit-content;
}

img{
    width: 100%;
}
.toHome{
    background-color: transparent;
    border: none;
    color: antiquewhite;
    font-size: xx-large;
    text-decoration: none;
}

@media (max-width: 768px) {
    .PageNotFound {
      padding-bottom: 40%;
      padding-top: 10%;
}
  .backdrop {
    transform: skewX(0deg);
    width: 85%;
    width: fit-content;
    justify-content: center;
    margin: 0 50px;
    text-decoration: none;
  }

  .content {
    padding: 5%;
    transform: skewX(0deg);
    width: 100%;
    height: 30vh;
    font-family: Lexend;
}
}
</style>
