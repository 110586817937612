const creativeTeamData = {
    Zander: {
        name: "Zander Fick",
        headShot: require("@/assets/pictures/creativeTeam/ZFick.webp"),
        lore: "Piece about what done and why any of this Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus commodo metus, sit amet dapibus ex mollis nec. Sed sit amet ex dolor. Mauris pharetra commodo lorem, in pulvinar ligula cursus vel. Nam quis congue quam. Mauris quam velit, malesuada at hendrerit a, sagittis sit amet tellus. In id tristique est. Nunc mauris nisi, placerat volutpat accumsan ac, tincidunt at erat. Aenean eu gravida ante. Sed ullamcorper, lorem et tincidunt luctus, est metus pharetra sapien, et pretium massa diam et est. Pellentesque quis nisi dolor. Morbi a mauris quis nisl malesuada viverra vitae ut odio. Nullam sodales ipsum eget augue."
    },
    Nica: {
        name: "Nica Schutze",
        headShot: require("@/assets/pictures/creativeTeam/NSchutze.webp"),
        lore: "Piece about what done and why any of this but different Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed cursus commodo metus, sit amet dapibus ex mollis nec. Sed sit amet ex dolor. Mauris pharetra commodo lorem, in pulvinar ligula cursus vel. Nam quis congue quam. Mauris quam velit, malesuada at hendrerit a, sagittis sit amet tellus. In id tristique est. Nunc mauris nisi, placerat volutpat accumsan ac, tincidunt at erat. Aenean eu gravida ante. Sed ullamcorper, lorem et tincidunt luctus, est metus pharetra sapien, et pretium massa diam et est. Pellentesque quis nisi dolor. Morbi a mauris quis nisl malesuada viverra vitae ut odio. Nullam sodales ipsum eget augue."
    }

}

export default creativeTeamData;