<template>
  <div class="musicPage">
    <div class="heading">
      <InformationPanel title="Music" :isMeetTheCharacters="false" />
    </div>
    <div v-for="(track, name, index) in 10" :key="index" class="musicPlayer">
      <Vue3WaveAudioPlayer
        :wave_width="width"
        :wave_height="height"
        :src="require('../assets/music/RickRoll.mp3')"
        :load_audio_onmount="true"
      />
      <div class="name">
        <h4>Track Name</h4>
      </div>
    </div>
  </div>
</template>

<script>
import InformationPanel from "../components/InformationPanel.vue";
import Vue3WaveAudioPlayer from "vue3-wave-audio-player";

export default {
  name: "CreativeTeam",
  components: {
    InformationPanel,
    Vue3WaveAudioPlayer,
  },
  data() {
    return {
      width: window.innerWidth * 0.5,
      height: window.innerWidth * 0.05,
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.width = window.innerWidth * 0.5;
        this.height = window.innerHeight * 0.05;
      }, 200);
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.musicPage {
  background-image: url("../assets/pictures/background.webp");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-bottom: 20%;
}

.musicPlayer {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  transform: skewX(-10deg);
  margin: 3% auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}

.musicPlayer h4 {
  color: blanchedalmond;
  font-family: Lexend;
  margin: 10px 0 0;
  text-align: justify;
  margin: 0;
}

.heading {
  transform: skewX(-5deg);
  width: 40%;
  margin: 0 auto;
  margin-bottom: 0;
}

.name {
  transform: skewX(-5deg);
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .musicPage {
    padding-bottom: 50%;
    padding-top: 10%;
  }
  .musicPlayer {
    transform: skewX(0deg);
    width: 90%;
    padding: 15px;
  }

  .musicPlayer p {
    font-size: 1em;
  }

  .heading {
    transform: skewX(-5deg);
    width: 80%;
    margin: 2% auto;
  }
}
</style>
