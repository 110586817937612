<template>
  <div class="video-container">
    <video
      v-show="!isReversing"
      autoplay
      muted
      playsinline
      id="forward-video"
      ref="forwardVideo"
      @ended="switchVideo"
    >
      <source :src="forwardVideo" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <video
      v-show="isReversing"
      autoplay
      muted
      playsinline
      id="reverse-video"
      ref="reverseVideo"
      @ended="switchVideo"
    >
      <source :src="reversedVideo" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <div v-if="videoLoaded" class="overlay">
      <h1 class="fade-in-heading" ref="headingText">Blink Water</h1>
      <span class="fade-in-span" ref="spanText">Die musiek byspel</span>

      <div class="fade-in-names" ref="nameText">
        <h3>CREATED AND WRITTEN BY</h3>
        <h1>ZANDER FICK AND NICA SCHUTZE</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeBanner",
  data() {
    return {
      forwardVideo: require('@/assets/videos/backDropMain.mp4'),
      reversedVideo: require('@/assets/videos/backDropMainReversed.mp4'),
      isReversing: false,
      videoLoaded: false,
    };
  },
  mounted() {
    const forwardVideo = this.$refs.forwardVideo;
    const reverseVideo = this.$refs.reverseVideo;

    this.$nextTick(() => {
      if (forwardVideo) {
        forwardVideo.addEventListener('canplaythrough', () => {
          this.videoLoaded = true;
          this.$emit('loaded');
        });

        forwardVideo.play().catch((error) => {
          console.error('Error attempting to play forward video:', error);
        });
      }

      // Handle visibility change
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          if (forwardVideo) forwardVideo.pause();
          if (reverseVideo) reverseVideo.pause();
        } else {
          if (this.isReversing && reverseVideo) {
            reverseVideo.play().catch((error) => {
              console.error('Error resuming reverse video playback:', error);
            });
          } else if (forwardVideo) {
            forwardVideo.play().catch((error) => {
              console.error('Error resuming forward video playback:', error);
            });
          }
        }
      });
    });
  },
  methods: {
    switchVideo() {
      this.isReversing = !this.isReversing;

      const forwardVideo = this.$refs.forwardVideo;
      const reverseVideo = this.$refs.reverseVideo;

      if (this.isReversing && reverseVideo) {
        reverseVideo.currentTime = 0;
        reverseVideo.play().catch((error) => {
          console.error('Error playing reverse video:', error);
        });
      } else if (forwardVideo) {
        forwardVideo.currentTime = 0;
        forwardVideo.play().catch((error) => {
          console.error('Error playing forward video:', error);
        });
      }
    },
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  height: 80vh;
  width: 90vw;
  margin: 5% 0;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(1px);
  box-shadow: 0px 0px 40px 40px rgba(0, 0, 0, 0.7);
  transition: opacity 1s ease-in-out;
}

video[v-show="true"] {
  opacity: 1;
}

video[v-show="false"] {
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: transparent !important;
}

.fade-in-heading {
  font-family: Lexend;
  color: blanchedalmond;
  font-size: 7rem;
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 2s;
  align-self: center;
  position: absolute;
  background-color: transparent !important;
}

.fade-in-span {
  opacity: 0;
  color: blanchedalmond;
  font-family: Caveat;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 3s;
  align-self: center;
  top: 10%;
  left: 20%;
  position: relative;
  font-size: 2em;
}

.fade-in-names {
  opacity: 0;
  color: beige;
  animation: fadeIn 2s ease-in-out forwards;
  animation-delay: 5s;
  align-self: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 1%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@font-face {
  font-family: "Caveat";
  src: url("../../assets/fonts/Caveat-Medium.ttf");
}

@media screen and (max-width: 768px) {
  .video-container {
  position: relative;
  height: 80vh;
  width: 95vw;
  margin: 0 0 5%;
}

  .fade-in-heading {
  font-size: 5rem;
}

.fade-in-span {
 
  top: 15%;
  left: 20%;
  position: relative;
  font-size: 1em;
}

.fade-in-names {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  font-size: 2rem;
  padding: 3%;
}

}
</style>
